import React from 'react';
import LoaderBase from 'react-loader-spinner';


const Loader = () => (

    <div style={{margin: "50px auto", width: "100px", height: "100px"}}>
        <LoaderBase
            type="Audio"
            color="#4caf50"
            height={100}
            width={100}
        />
    </div>
);


export default Loader;
