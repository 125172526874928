import React from 'react';
import {Link} from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import logo from '../../assets/logo.png';


const Navigation = ({mobile = false}) => (
    <nav className={"site-header sticky-top py-1" + (mobile ? "" : " d-none d-md-flex")}>
        <div className="container  d-flex flex-column flex-sm-row justify-content-between">
            <Link to={ROUTES.HOME} className="py-2">
                <img src={logo} height="24" className="d-block mx-auto"/>
            </Link>
            <h3 className="py-2 m-0 d-none d-sm-block"><span className="badge badge-pill badge-primary">BETA</span></h3>

        </div>
    </nav>
);


export default Navigation;
