import React, {Suspense, lazy} from 'react';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'

import './App.scss';

import * as ROUTES from '../constants/routes';
import GoogleAnalytics from '../utils/GoogleAnalytics'
import Navigation from "../components/Navigation";
import Loader from "../components/Loader";
import MetaTags from "../utils/MetaTags";


const Home = lazy(() => import('../containers/Home'));
const Exam = lazy(() => import('../LEGACY/Exam'));

const App = () => (
    <BrowserRouter>
        <Suspense fallback={<><MetaTags.Default /><Navigation/><Loader/></>}>
            {GoogleAnalytics.init() && <GoogleAnalytics.RouteTracker/>}
            <Switch>
                <Route exact path={ROUTES.HOME} component={Home}/>
                <Route path={ROUTES.EXAM} component={Exam}/>
                <Redirect to={ROUTES.HOME}/>
            </Switch>
        </Suspense>
    </BrowserRouter>
);

export default App;
