import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'

class GoogleAnalyticsRouteTracker extends Component {
    componentDidMount () {
        this.logPageChange(
            this.props.location.pathname,
            this.props.location.search
        )
    }

    componentDidUpdate ({ location: prevLocation }) {
        const { location: { pathname, search } } = this.props;
        const isDifferentPathname = pathname !== prevLocation.pathname;
        const isDifferentSearch = search !== prevLocation.search;

        if (isDifferentPathname || isDifferentSearch) {
            this.logPageChange(pathname, search)
        }
    }

    logPageChange (pathname, search = '') {
        const page = pathname + search;
        const { location } = window;
        ReactGA.set({
            page,
            location: `${location.origin}${page}`,
            ...this.props.options
        });
        ReactGA.pageview(page)
    }

    render () {
        return null
    }
}

const RouteTracker = () =>
    (<Route component={GoogleAnalyticsRouteTracker} />);

const init = (options = {}) => {
    // const env = window._env_ || {};
    const isGAEnabled = !!'UA-179501699-1'; //TODO: REACT_APP_GA_TRACKING_ID move to config

    if (isGAEnabled) {
        ReactGA.initialize(
            'UA-179501699-1', { //TODO: REACT_APP_GA_TRACKING_ID move to config
                debug: false, //env.REACT_APP_GA_DEBUG === 'true', //TODO: move to config
                ...options
            }
        )
    }

    return isGAEnabled
};

export default {
    GoogleAnalyticsRouteTracker,
    RouteTracker,
    init
}

GoogleAnalyticsRouteTracker.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    }).isRequired,
    options: PropTypes.object
};