import React  from 'react'
import Helmet from "react-helmet";

const Default = () =>
    (
        <>
            <Helmet>
                <title>ePrawko.pl - Darmowy egzamin teoretyczny na prawo jazdy!</title>
                <meta name="title" content="ePrawko.pl - Darmowy egzamin teoretyczny na prawo jazdy!"/>
                <meta name="description"
                      content="Darmowa aplikacja, symulator państwowego egzaminu na prawo jazdy. Aktualna baza pytań (2020). Kat. A/A1/AM, Kat. B/B1, Kat. C/C1, Kat. D/D1, Kat. PT, Kat. T."/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://eprawko.pl/"/>
                <meta property="og:title" content="ePrawko.pl - Darmowy egzamin teoretyczny na prawo jazdy!"/>
                <meta property="og:description"
                      content="Darmowa aplikacja, symulator państwowego egzaminu na prawo jazdy. Aktualna baza pytań (2020). Kat. A/A1/AM, Kat. B/B1, Kat. C/C1, Kat. D/D1, Kat. PT, Kat. T."/>
                <meta property="og:image" content="https://eprawko.pl/og_image.jpg"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content="https://eprawko.pl/"/>
                <meta property="twitter:title" content="ePrawko.pl - Darmowy egzamin teoretyczny na prawo jazdy!"/>
                <meta property="twitter:description"
                      content="Darmowa aplikacja, symulator państwowego egzaminu na prawo jazdy. Aktualna baza pytań (2020). Kat. A/A1/AM, Kat. B/B1, Kat. C/C1, Kat. D/D1, Kat. PT, Kat. T."/>
                <meta property="twitter:image" content="https://eprawko.pl/og_image.jpg"/>

            </Helmet>
        </>
    );

const Exam = ({categoryCode}) =>
    (
        <>
            <Helmet>
                <title>ePrawko.pl - Kategoria {categoryCode} - darmowy egzamin teoretyczny online!</title>
                <meta name="title" content={"ePrawko.pl - Kategoria "+categoryCode.toUpperCase()+" - darmowy egzamin teoretyczny online!"}/>
                <meta name="description"
                      content="Darmowa aplikacja, symulator państwowego egzaminu na prawo jazdy. Aktualna baza pytań (2020). Kat. A/A1/AM, Kat. B/B1, Kat. C/C1, Kat. D/D1, Kat. PT, Kat. T."/>

                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://eprawko.pl/egzamin/"+categoryCode}/>
                <meta property="og:title" content={"ePrawko.pl - Kategoria "+categoryCode.toUpperCase()+" - darmowy egzamin teoretyczny online!"}/>
                <meta property="og:description"
                      content="Darmowa aplikacja, symulator państwowego egzaminu na prawo jazdy. Aktualna baza pytań (2020). Kat. A/A1/AM, Kat. B/B1, Kat. C/C1, Kat. D/D1, Kat. PT, Kat. T."/>
                <meta property="og:image" content="https://eprawko.pl/og_image.jpg"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={"https://eprawko.pl/egzamin/"+categoryCode}/>
                <meta property="twitter:title"
                      content={"ePrawko.pl - Kategoria "+categoryCode.toUpperCase()+" - darmowy egzamin teoretyczny online!"}/>
                <meta property="twitter:description"
                      content="Darmowa aplikacja, symulator państwowego egzaminu na prawo jazdy. Aktualna baza pytań (2020). Kat. A/A1/AM, Kat. B/B1, Kat. C/C1, Kat. D/D1, Kat. PT, Kat. T."/>
                <meta property="twitter:image" content="https://eprawko.pl/og_image.jpg"/>

            </Helmet>
        </>
    );

export default {
    Default,
    Exam
}